import React from 'react';
import { Table, Progress } from 'reactstrap';

function TabelaDados({ dados }) {
  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Espécie</th>
          <th scope="col">Nome Cientifico</th>
          <th scope="col">Carro chefe</th>
          <th scope="col">Ocupação</th>
          <th scope="col">Produtividade</th>
          <th scope="col">Renda</th>
          <th scope="col">Quantidade</th>
          <th scope="col">Tipo Canteiro</th>
          <th scope="col">Estágio sucessão</th>
          <th scope="col">Participação %</th>
        </tr>
      </thead>
      <tbody>
        {dados.map((item) => (
          <tr key={item.index}>
            <th scope="row">{item.nome}</th>
            <td>{item.produtividade}</td>
            <td>{item.renda}</td>
            <td>{item.quantidade}</td>
            <td>
                <div className="d-flex align-items-center">
                    <span className="mr-2">{item.percenty}%</span>
                    <div>
                      <Progress
                        max="100"
                        value={item.percenty}
                        barClassName={
                          item.percenty < 30
                            ? "bg-danger" // Vermelho para abaixo de 30%
                            : item.percenty > 80
                            ? "bg-success" // Verde para acima de 80%
                            : item.percenty > 60
                            ? "bg-primary" // Azul para acima de 60%
                            : "bg-warning" // laranja para entre 30% e 60%
                        }
                      />
                    </div>
                </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default TabelaDados;
