import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Card, CardBody, CardTitle } from 'reactstrap';
import './IncomeFirstYearGraph.css';
 
  const options = {
    responsive: true,
    title: {
      display: false,
    },
    tooltips: {
      callbacks: {
        title: function () {
          return ''; // Remove o título do tooltip
        },
        label: function (tooltipItem) {
          return `R$ ${tooltipItem.yLabel}`; // Exibe apenas o valor com o prefixo "R$"
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0, // Impede que o gráfico tenha valores negativos
            callback: function (value) {
              return ''; // Remove os rótulos do eixo Y
            },
          },
        },
      ],
      xAxes: [
        {
          display: true, // Mostra o eixo X
          ticks: {
            autoSkip: false, // Desabilita o autoSkip para garantir que todos os pontos sejam exibidos
            display: false,
          },
        },
      ],
    },
  };
  
  

const IncomeFirstYearGraph = ({ title, incomeFirstYear }) => {
  const maxIncome = Math.max(...incomeFirstYear.data);
  const sumIncome = incomeFirstYear.data.reduce((acc, valor) => acc + valor, 0);
  const growthPercentage = (maxIncome / sumIncome) * 100;

  const data = {
    labels: incomeFirstYear.labels,
    datasets: [
      {
        data: incomeFirstYear.data,
        borderColor: 'black',
        backgroundColor: 'rgba(0,0,0,0.1)',
        fill: true,
        tension: 0.3,
        borderWidth: 3,
      },
    ],
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="d-flex flex-column">
          <div tag="h5" className="text-uppercase text-muted mb-1">{title}</div>
          <div className="justify-content-start">
            <b className="h3 font-weight-bold mb-0 mr-1 font-size-20">R${maxIncome}</b>
            <span className="mt-3 mb-0 text-muted text-sm">
              <span className={growthPercentage >= 0 ? "text-success mr-2" : "text-danger mr-2"}>
                {growthPercentage >= 0 ? `+${growthPercentage.toFixed(2)}%` : `${growthPercentage.toFixed(2)}%`}
              </span>
            </span>
          </div>
        </CardTitle>
      </CardBody>
      <div className='w-100 line-graph'>
          <Line data={data} options={options} />
      </div>
    </Card>
  );
};

export default IncomeFirstYearGraph;
