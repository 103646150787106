import React, { useState } from "react";
import AverageIncomeGraph from "components/Graphics/AverageIncome/AverageIncomeGraph";
import ProductivityGraph from "components/Graphics/Productivity/ProductivityGraph";
import MenuFilter from "components/MenuFilter/MenuFilter";
import { Container, Row } from "reactstrap";
import styles from '../../layouts/Auth.module.css';

//const species = ['Orquídea', 'Suculenta', 'Bonsai', 'Samambaia', 'Cacto', 'Hortelã'];
const showIcon = true;

const NewHeader = ({species, prodTotal, prodMedia, rendaTotal, rendaMedia }) => {

  const filteredSpecies = species.filter(especie => especie.carrochefe === true).slice(0, 7);
  const nomesComuns = filteredSpecies.map(especie => especie.nome_comum);

  const [selectedSpecies, setSelectedSpecies] = useState(null);

  const handleFilterChange = (species) => {
    setSelectedSpecies(species);
  };

  const getGraphData = () => {
    if (!selectedSpecies) {
      return {
        prodTotal: prodTotal.toFixed(0), 
        prodMedia: prodMedia.toFixed(0), 
        rendaTotal: rendaTotal.toFixed(0), 
        rendaMedia: rendaMedia.toFixed(0), 
        percentageChange: Math.floor(Math.random() * 15)
      };
    }

    else {
      const infoSpecie = filteredSpecies.find(especie => especie.nome_comum === selectedSpecies);

      if (infoSpecie) {
        const { produtividade_anual, produtividade_total, renda_anual, renda_total } = infoSpecie;
        return {
          prodTotal: produtividade_total.toFixed(0), 
          prodMedia: produtividade_anual.toFixed(0), 
          rendaTotal: renda_total.toFixed(0), 
          rendaMedia: renda_anual.toFixed(0), 
          percentageChange: Math.floor(Math.random() * 15)
        };
      } 
    }
  };

  return (
    <div className={`header pb-8 pt-5 pt-md-8 ${styles.headerBackground}`}>
    <div className={styles.overlay}></div>
      <Container fluid className={styles.headerContent}>
        <div className="header-body">
          <div className="mb-3">
            <MenuFilter 
              data={nomesComuns} 
              showIcon={showIcon} 
              allDataLabel="Todas as Espécies" 
              onFilterChange={handleFilterChange} 
            />
          </div>
          <Row>
            <ProductivityGraph
              title="Produtividade Total"
              percentageChange={getGraphData().percentageChange}
              lastSimulation={getGraphData().prodTotal}
            />
            <AverageIncomeGraph
              title="Renda Total"
              percentageChange={getGraphData().percentageChange}
              lastSimulation={getGraphData().rendaTotal}
            />
            <ProductivityGraph
              title="Produtividade Anual"
              percentageChange={getGraphData().percentageChange}
              lastSimulation={getGraphData().prodMedia}
            />
            <AverageIncomeGraph
              title="Renda Anual"
              percentageChange={getGraphData().percentageChange}
              lastSimulation={getGraphData().rendaMedia}
            />
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default NewHeader;
