/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useEffect, useState } from "react";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody
} from "reactstrap";

import { useParams, useHistory } from 'react-router-dom';

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.js";

import TabelaDadosV2 from "variables/tableV2.js";

import NewHeader from "components/Headers/NewHeader.js";

import axios from "axios";
import HigherMonthlyIncomeGraph from "components/Graphics/HigherMonthlyIncomeGraph/HigherMonthlyIncomeGraph";
import GenericGraph from "components/Graphics/GenericGraph/GenericGraph";
import PieChartCard from "components/Graphics/PieChartCard/PieChartCard";
import IncomeFirstYearGraph from "components/Graphics/IncomeFirstYear/IncomeFirstYearGraph";
import ProductivityFirstYearGraph from "components/Graphics/ProductivityFirstYear/ProductivityFirstYearGraph";
import ProductionPlanGraph from "components/Graphics/ProductionPlan/ProductionPlanGraph";
import styles from '../layouts/Auth.module.css';
import '../views/newIndex.css';

// Import dados mockados
import dadosJson from '../components/utils/spp-mvp-beberibe.json';

const NewIndex = () => {
  const { id } = useParams();

  // if (!id) {
  //   history.push("/auth/begin");
  // }

  // Dados das espécies para tabela e graficos
  const dataTable = dadosJson.species;
  const dataGrafAnual = dadosJson.anual;
  const dataGrafPerene = dadosJson.perene;
  const dataGrafSemiperene = dadosJson.semiperene;
  const dataGrafProd1Ano = dadosJson.prod_prim_ano;
  const dataGrafRenda1Ano = dadosJson.renda_prim_ano;
  const dataGrafProdTotal = dadosJson.prod_total_graf;
  const dataGrafRendaTotal = dadosJson.renda_total_graf;

  useEffect(() => {
    //https://reflora.app.br:5001/api/v1/production/code/${id}

    //const fetchData = async () => {
    axios.get(`http://localhost:5001/api/v1/production/code/${id}`).then((response) => {


    }).catch((error) => {
      // Handle error if necessary
      console.error(error);
    });
  }, [id]);


  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }


  //Graficos Gerais
  const data = {
    labels: dataGrafProdTotal.labels || [],
    values: dataGrafProdTotal.data || [],
  };

  const data2 = {
    labels: dataGrafRendaTotal.labels || [],
    values: dataGrafRendaTotal.data || [],
  };
  return (
    <>
      <NewHeader species = {dataTable} prodTotal = {dadosJson.prod_total} prodMedia = {dadosJson.prod_media} rendaTotal = {dadosJson.renda_total} rendaMedia = {dadosJson.renda_media} />
      {/* Page content */}
      <Container fluid className= {`mt--7 ${styles.headerContent}`}>
        <Row className="mb-3 d-flex">
          <Col lg="4" xl="5" className="col-prod-first">
              <span className="productivity-first">
                <ProductivityFirstYearGraph title="Produtividade Primeiro ano" productivityFirstYear={dataGrafProd1Ano}/>
              </span>
          </Col>
          <Col lg="4" xl="4" className="col-income-first">
              <span className="income-first">
                <IncomeFirstYearGraph title="Renda Primeiro ano" incomeFirstYear={dataGrafRenda1Ano} />
              </span>
          </Col>
          <Col lg="4" xl="3">
              <span className="higher-income-graph">
                <HigherMonthlyIncomeGraph/>
              </span>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
            <ProductionPlanGraph species = {dataTable} />
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Espécies</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Ver todos
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <TabelaDadosV2 dados={dataTable} />
            </Card>
          </Col>
        </Row>
        <Row className="d-flex line-pie-chart mt-5">
          <Col xs="12" sm="6" lg="4" xl="4" className="col-pie">
            <div className="pie-chart">
              <PieChartCard title='Principais espécies - Perene' dataGraf = {dataGrafPerene} />
            </div>
          </Col>
          <Col xs="12" sm="6" lg="4" xl="4" className="col-pie">
            <div className="pie-chart pie-chart-2">
              <PieChartCard title='Principais espécies - Anual' dataGraf = {dataGrafAnual} />
            </div>
          </Col>
          <Col xs="12" sm="6" lg="4" xl="4" className="col-pie">
            <div className="pie-chart pie-chart-3">
              <PieChartCard title='Principais espécies - Semiperene' dataGraf = {dataGrafSemiperene} />
            </div>
          </Col>
        </Row>
        <Row className="mb-3 mt-5">
          {/* Coluna para a imagem */}
          <Col xs="12" md="6" lg="4" xl="4" >
            <Card className="shadow">
              <CardBody className="d-flex justify-content-center align-items-center">
                <img src="/img/safs/saf-beberibe.png" alt="Descrição da imagem" style={{ maxWidth: '100%', height: 'auto', maxHeight: '600px' }}/>
              </CardBody>
            </Card>
          </Col>
          {/* Coluna para os gráficos */}
          <Col xs="12" md="6" lg="4" xl="8" >
            <div>
              {/* Primeiro gráfico */}
              <div className="mb-3">
                <GenericGraph title="Produção Geral" data={data} />
                </div>
              {/* Segundo gráfico */}
                <GenericGraph title="Renda Geral" data={data2} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NewIndex;
