import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import AverageIncomeGraphIcon from "../../../assets/img/icons/graphics/StatisticsCardIconRoound.svg";
import './AverageIncomeGraph.css';
 

function formatNumber(number) {
  if (typeof number === "string") {
    number = number.replace(/\./g, '').replace(',', '.');
  }
  number = parseFloat(number);

  if (number >= 1000 && number <= 999999) {
    const formattedNumber = (number / 1000).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return formattedNumber + ' mil';
  } else if (number >= 1000000 && number <= 9999999) {
    const formattedNumber = (number / 1000000).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return formattedNumber + ' mi';
  }
  
  return number.toFixed(0).toString();
  
}

const AverageIncomeGraph = ({ title, percentageChange, lastSimulation }) => {

  return (
    <Col lg="6" xl="3">
      <Card className="card-stats mb-4 mb-xl-0 border-card">
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle tag="h5" className="text-uppercase text-muted mb-1 title">
                {title}
              </CardTitle>
              <span className="h3 font-weight-bold mb-0 last-simulation">R${formatNumber(lastSimulation)}</span>
            </div>
            <Col className="col-auto">
            <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                <img src={AverageIncomeGraphIcon} className="statistic-icon" alt="Statistics Icon" />
            </div>
            </Col>
          </Row>
          <p className="mt-3 mb-0 text-muted text-sm">
            <span className={percentageChange >= 0 ? "text-success mr-2" : "text-danger mr-2"}>
              <i className={percentageChange >= 0 ? "fa fa-arrow-up" : "fa fa-arrow-down"} /> {Math.abs(percentageChange)}%
            </span>
            <span className="text-nowrap">Por ano</span>
          </p>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AverageIncomeGraph;
