import React, { useState } from 'react';
import { FaCube, FaCog } from 'react-icons/fa';
import './index.css';

const MenuFilter = ({ data, showIcon, allDataLabel, onFilterChange = () => {} }) => {
  const [selected, setSelected] = useState(null);

  const handleSelect = (index) => {
    setSelected(index);
    onFilterChange(index !== null ? data[index] : null);
  };

  return (
    <div className="w-full flex gap-2 p-0 rounded-[12px] shadow-sm menu-filter-container">
      <div className="flex gap-2 w-full rounded-[8px] shadow-inner p-2">
        <button 
          className={`px-4 py-2 text-sm rounded-md transition mr-1 menu-filter-button-all ${selected === null ? 'text-black' : 'shadow-none z-0'}`}
          onClick={() => handleSelect(null)}
        >
          {showIcon && <FaCube className="menu-filter-icon" />} 
          {allDataLabel}
        </button>
        {data.map((item, index) => (
          <button 
            key={index} 
            className={`px-4 py-2 text-sm rounded-md transition menu-filter-button ${selected === index ? 'menu-filter-button-all text-black z-2' : 'bg-green-500 hover:bg-green-600'}`}
            onClick={() => handleSelect(index)}
          >
            {showIcon && <FaCog className="menu-filter-icon" />} 
            {item}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MenuFilter;
