import React from 'react';
import { Line } from 'react-chartjs-2';
import { Card, CardBody, CardTitle, Progress } from 'reactstrap';
import './ProductivityFirstYearGraph.css';

const options = {
  responsive: true,
  title: {
    display: false,
  },
  tooltips: {
    callbacks: {
      title: function () {
        return ''; // Remove o título do tooltip
      },
      label: function (tooltipItem) {
        return `R$ ${tooltipItem.yLabel}`; // Exibe apenas o valor com o prefixo "R$"
      },
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          min: 0, // Impede que o gráfico tenha valores negativos
          callback: function (value) {
            return ''; // Remove os rótulos do eixo Y
          },
        },
      },
    ],
    xAxes: [
      {
        display: true, // Mostra o eixo X
        ticks: {
          autoSkip: false, // Desabilita o autoSkip para garantir que todos os pontos sejam exibidos
        },
      },
    ],
  },
};

const ProductivityFirstYearGraph = ({ title, productivityFirstYear }) => {
  const maxProductivity = Math.max(...productivityFirstYear.data);
  const sumProductivity = productivityFirstYear.data.reduce((acc, valor) => acc + valor, 0);
  const progressValue = ((maxProductivity / sumProductivity) * 100).toFixed(2);

  const data = {
    labels: productivityFirstYear.labels,
    datasets: [
      {
        data: productivityFirstYear.data,
        backgroundColor: 'rgba(94, 114, 228, 0.2)', // Roxo claro e transparente
        fill: true,
        tension: 0.3,
        pointBackgroundColor: '#5E72E4', // Cor das bolinhas
        pointBorderColor: '#5E72E4', // Cor da borda das bolinhas
        pointRadius: 7, // Tamanho das bolinhas
        pointHoverRadius: 8, // Tamanho das bolinhas quando passar o mouse
      },
    ],
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="d-flex align-items-center">
          <div className="icon-box" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><i className="ni ni-chart-bar-32" style={{fontSize: '30px', color: '#fff' }}></i></div>
          <div className="d-flex flex-column flex-grow-1">
            <div tag="h5" className="text-uppercase text-muted">
              {title}
            </div>
            <b className="h3 font-weight-bold mb-0 font-size-20" style={{ fontSize: '20px' }}>
              R${maxProductivity}
            </b>
          </div>
          <div className="progress-container">
            <div className="progress-percentage text-muted">{progressValue}%</div>
            <Progress
              value={progressValue}
              max={100}
              color="primary"
              className="custom-progress-bar"
            />
          </div>
        </CardTitle>
      </CardBody>
      <div className="w-100 canva-monitor">
        <Line data={data} options={options} />
      </div>
    </Card>
  );
};

export default ProductivityFirstYearGraph;
