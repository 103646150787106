import React, { useRef, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Line } from "react-chartjs-2";
import "./GenericGraph.css";

let labels = [
  "Jan",
  "fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "ago",
  "set",
  "out",
  "nov",
  "dez",
];

const GenericGraph = ({ title, data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartInstance = chartRef.current.chartInstance;
    const ctx = chartInstance.ctx;

    const gradient = ctx.createLinearGradient(
      0,
      0,
      0,
      chartInstance.chartArea.bottom
    );
    gradient.addColorStop(0, "rgba(89, 108, 255, 0.22)");
    gradient.addColorStop(1, "rgba(255, 255, 255, 0)");

    chartInstance.data.datasets[0].backgroundColor = gradient;
    chartInstance.update();
  }, []);

  const chartData = {
    labels: data.labels ?? labels,
    datasets: [
      {
        fill: true,
        lineTension: 0.1,
        borderColor: "#596CFF",
        borderWidth: 2,
        data: data.values,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            display: false,
          },
          gridLines: {
            display: true,
            color: "#DEE2E6",
            lineWidth: 1,
          },
        },
      ],
    },
  };

  return (
    <Card className="shadow">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h4 className="text-uppercase ls-1 mb-1">{title}</h4>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        {/* Chart */}
        <div className="chart" style={{ height: '200px', maxWidth: '100%' }}>
          <Line ref={chartRef} data={chartData} options={options} />
        </div>
      </CardBody>
    </Card>
  );
};

export default GenericGraph;
