import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import './PieChartCard.css';

const PieChartCard = ({
  title,
  filters = [
    { id: 1, label: 'Produção' },
    { id: 2, label: 'Renda' },
  ],
  dataGraf
}) => {
  // Estado para o filtro selecionado
  const [selectedFilter, setSelectedFilter] = useState(filters[0]?.id || 1);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  // Função para criar os dados do gráfico com base no filtro
  const getChartData = (filterId) => {
    if (filterId === 1) {
      return {
        labels: [...dataGraf.labels.slice(0,5)], // Garantimos que as labels sejam copiadas
        datasets: [
          {
            data: [...dataGraf.data.slice(0,5)], // Copiamos os valores de data para evitar mutações
            backgroundColor: ['#A8B8D8', '#11CDEF', '#5E72E4', '#172B4D'],
            borderColor: ['#A8B8D8', '#11CDEF', '#5E72E4', '#172B4D'],
            borderWidth: 1,
          },
        ],
      };
    } else if (filterId === 2) {
      return {
        labels: [...dataGraf.labels.slice(0,5)], // Garantimos que as labels sejam copiadas
        datasets: [
          {
            data: [...dataGraf.data2.slice(0,5)], // Copiamos os valores de data2 para evitar mutações
            backgroundColor: ['#A8B8D8', '#11CDEF', '#5E72E4', '#172B4D'],
            borderColor: ['#A8B8D8', '#11CDEF', '#5E72E4', '#172B4D'],
            borderWidth: 1,
          },
        ],
      };
    } else {
      return {
        labels: [],
        datasets: [],
      };
    }
  };

  // Atualiza os dados do gráfico com base no filtro selecionado
  useEffect(() => {
    const updatedChartData = getChartData(selectedFilter);
    setChartData(updatedChartData);
  }, [selectedFilter, dataGraf]);

  // Função para alterar o filtro
  const handleFilterChange = (filterId) => {
    setSelectedFilter(filterId);
  };

  return (
    <div className="card-pie">
      <div className="header-pie">
        <h2 className="title-pie">{title}</h2>
      </div>
      <div className="content-pie">
        <div className="buttons-pie">
          {filters.map((filter) => (
            <button
              key={filter.id}
              id={filter.id}
              className={`button-pie ${
                selectedFilter === filter.id ? 'selected' : ''
              }`}
              onClick={() => handleFilterChange(filter.id)}
            >
              <span className='button-label'>{filter.label}</span>
            </button>
          ))}
        </div>
        <div className="chartContainer-pie">
          <Pie data={chartData} options={{ responsive: true }} />
        </div>
      </div>
    </div>
  );
};

export default PieChartCard;