import React from "react";
import { Button, Progress } from "reactstrap";
import "./HigherMonthlyIncomeGraph.css"; // Importando o arquivo CSS

const HigherMonthlyIncomeGraph = ({
  monthlyIncome = "150 mil",
  productionProgress = 70,
  biomassProgress = 30,
  incomePercentage = 80,
  incomeValue = "120 mil",
}) => {
  return (
    <div className="graph-container">
      {/* Título com o valor e "Renda Mensal" */}
      <div className="income-title">
        <div className="income-header">
          <div className="currency-symbol">R$</div>
          <div className="income-value">{monthlyIncome}</div>
        </div>
        <div className="income-label">Renda Média</div>
      </div>

      {/* Porcentagem e valor da renda */}
      <div className="income-details">
        <span className="percentage mr-1">{incomePercentage}%</span>
        <span className="income-amount">R${incomeValue}</span>
      </div>

      {/* Botão Gerar Relatório */}
      <a
            href="/docs/Reflora-Sua-plataforma-de-sistemas-agroflorestais.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
      <div className="generate-report">
        <Button className="generate-btn">
         
            Gerar Relatório
          
        </Button>
      </div>
      </a>

      {/* Barras de Progresso */}
      <div className="progress-bars">
        {/* Barra de Produção */}
        <div className="progress-section">
          <div className="progress-header">
            <div className="progress-title">
              Produção: {productionProgress}%{" "}
              {/* Exibe o valor logo após o ":" */}
            </div>
          </div>
          <Progress value={productionProgress} className="production-bar" />
        </div>

        {/* Barra de Biomassa */}
        <div className="progress-section">
          <div className="progress-header">
            <div className="progress-title">Biomassa: {biomassProgress}%</div>
            {/* <div className="progress-percentage">{biomassProgress}%</div> */}
          </div>
          <Progress value={biomassProgress} className="biomass-bar" />
        </div>
      </div>
    </div>
  );
};

export default HigherMonthlyIncomeGraph;
