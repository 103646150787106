import React from "react";
import { HorizontalBar } from "react-chartjs-2";

const shortenBarsPlugin = {
  beforeDraw: function(chart) {
    chart.data.datasets.forEach((dataset, datasetIndex) => {
      chart.getDatasetMeta(datasetIndex).data.forEach((bar, index) => {

        const vm = bar._view;

        if (bar && dataset.data[index] > 0) {
          const value = dataset.data[index]; // Valor da barra
          const originalX = vm.x;
          const base = vm.base;
          
          // Se originalX e base forem números e a barra tiver comprimento (originalX > base)
          if (typeof originalX === "number" && typeof base === "number" && originalX > base) {
            if (value < 31){
              vm.x = 188;
            } 
            else if (value > 30 && value < 91) {
              vm.x = 164;
              const offset = 190; // deslocamento desejado em pixels
              vm.base = offset;
              vm.x += offset;
            }
            else if( value > 90 && value < 181){
              vm.x = 145;
              const offset = 345; // deslocamento desejado em pixels
              vm.base = offset;
              vm.x += offset;
            } 
            else if (value > 180 && value < 366) {
              vm.x = 164;
              const offset = 443; // deslocamento desejado em pixels
              vm.base = offset;
              vm.x += offset;
            }
            else if (value > 365 && value < 1096) {
              vm.x = 225;
              const offset = 552; // deslocamento desejado em pixels
              vm.base = offset;
              vm.x += offset;
            }
            else if (value > 1095 && value < 1826) {
              vm.x = 150;
              const offset = 703; // deslocamento desejado em pixels
              vm.base = offset;
              vm.x += offset;
            }
            else if (value > 1825 && value < 3651) {
              vm.x = 150;
              const offset = 803; // deslocamento desejado em pixels
              vm.base = offset;
              vm.x += offset;
            }
            else if (value > 3650 && value < 7301) {
              vm.x = 150;
              const offset = 903; // deslocamento desejado em pixels
              vm.base = offset;
              vm.x += offset;
            }
            else if (value > 7300) {
              vm.x = 158;
              const offset = 1003; // deslocamento desejado em pixels
              vm.base = offset;
              vm.x += offset;
            }
          }
        }
      });
    });
  },
  afterDraw: function (chart) {
    const ctx = chart.ctx;
    const scale = chart.scales["x-axis-0"];
    const minValue = scale.options.ticks.min; // Ex: 30
    ctx.font = "bold 14px 'Open Sans', sans-serif";
    ctx.fillStyle = "white";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
  
    chart.data.labels.forEach((label, index) => {
      // Soma os valores empilhados para esta linha
      const totalValue = chart.data.datasets.reduce(
        (sum, dataset) => sum + dataset.data[index],
        0
      );
      
      // Garante que não seja menor que o mínimo da escala
      const effectiveValue = totalValue < minValue ? minValue : totalValue;
      
      // Converte os valores para pixels usando a escala
      const leftPixel = scale.getPixelForValue(minValue);
      const rightPixel = scale.getPixelForValue(effectiveValue);
  
      // Calcula a posição central em pixels
      let xPos = (leftPixel + rightPixel) / 2;

      if(effectiveValue > 10){
         xPos = 100
      }
  
      if (effectiveValue > 30) {
        xPos = 270;
      }

      if (effectiveValue > 90) {
        xPos = 423;
      }

      if (effectiveValue > 180) {
        xPos = 530;
      }

      if (effectiveValue > 365) {
        xPos = 653;
      }

      if (effectiveValue > 1095) {
        xPos = 773;
      }

      if (effectiveValue > 1825) {
        xPos = 863;
      }

      if (effectiveValue > 3650) {
        xPos = 970;
      }

      if (effectiveValue > 7300) {
        xPos = 1073;
      }
      
      const meta = chart.getDatasetMeta(0);
      const bar = meta.data[index];
      if (bar) {
        const yPos = bar._model.y;
        ctx.fillText(label, xPos, yPos);
      }
    });
  }
  
};

const ticks = [
  { value: 0, label: "0 dias" },
  { value: 30, label: "30 dias" },
  { value: 90, label: "90 dias" },
  { value: 180, label: "180 dias" },
  { value: 365, label: "365 dias" },
  { value: 365 * 2.5, label: "3 anos" },
  { value: 365 * 6, label: "6 anos" },
  { value: 365 * 10, label: "10 anos" },
  { value: 365 * 20, label: "20 anos" },
  { value: 365 * 50, label: "50 anos" }
];

const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { 
    display: true,
    fullWidth: true
  },
  tooltips: {
    position: "average",
    yAlign: "bottom",
    custom: function (tooltipModel) {
      if (!tooltipModel || !tooltipModel.body) return;
    
      const tooltipItem = tooltipModel.dataPoints?.[0]; // Obtém o primeiro item da tooltip
      if (!tooltipItem) return;
    
      let value = tooltipItem.x;
      let effectiveValue = tooltipItem.value

      if(tooltipModel.dataPoints?.[0]?.value > 0){
        effectiveValue = tooltipModel.dataPoints?.[0]?.value
      }
      else if(tooltipModel.dataPoints?.[1]?.value > 0){
        effectiveValue = tooltipModel.dataPoints?.[1]?.value
      }
      else if(tooltipModel.dataPoints?.[2]?.value > 0){
        effectiveValue = tooltipModel.dataPoints?.[2]?.value
      }
      else if(tooltipModel.dataPoints?.[3]?.value > 0){
        effectiveValue = tooltipModel.dataPoints?.[3]?.value
      }

      if(effectiveValue > 10){
         value = 100
      }
  
      if (effectiveValue > 30) {
        value = 270;
      }

      if (effectiveValue > 90) {
        value = 423;
      }

      if (effectiveValue > 180) {
        value = 530;
      }

      if (effectiveValue > 365) {
        value = 653;
      }

      if (effectiveValue > 1095) {
        value = 773;
      }

      if (effectiveValue > 1825) {
        value = 863;
      }

      if (effectiveValue > 3650) {
        value = 900;
      }
      
      tooltipModel.x = value;
    },
    callbacks: {
      label: function (tooltipItem, data) {
        const dataset = data.datasets[tooltipItem.datasetIndex];
        const value = tooltipItem.xLabel;
        if (value === 0) return null;
        return `${dataset.label}: ${value} dias`;
      }
    }
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        position: "top",
        type: "logarithmic",
        ticks: {
          min: 10,
          max: 365 * 50,
          callback: function (value) {
            const tick = ticks.find(t => t.value === value);
            return tick ? tick.label : "";
          },
          autoSkip: false,
          maxTicksLimit: 10
        },
        afterBuildTicks: function (scale) {
          scale.ticks = ticks.map(t => t.value);
        },
        categoryPercentage: 0.8,
        barPercentage: 0.9,
        gridLines: { display: false }
      }
    ],
    yAxes: [
      {
        stacked: true,
        gridLines: {
          display: true,   // ativa as linhas horizontais (eixo y)
          drawOnChartArea: true,
          color: "rgba(0,0,0,0.1)", // exemplo de cor
          lineWidth: 1
        },
        ticks: {
          display: false
        },
      }
    ]
  },
  plugins: {
    labelsInBars: {
      enabled: true
    }
  }
};

const ProductionPlanGraph = ( {species} ) => {
  //const nomesComuns = species.map(especie => especie.nome_comum);

  const nomesComuns = ['Mamão', 'Cajá', 'Milho', 'Girassol', 'Eucalipto', 'Crotalária juncea', 'Crotalária spectabilis', 'Banana prata', 'Manga', 'Palma forrageira', 'Mandioca', 'Moringa', 'Feijão guandu', 'Feijão-de-corda', 'Mamona', 'Mandacaru', 'Capim massai', 'Canafístula', 'Graviola', 'Feijão-de-porco', 'Urucum', 'Abacaxi', 'Gliricidia', 'Melancia', 'Cacau', 'Jerimum/abóbora'];
  const data = {
    labels: nomesComuns,
    datasets: [
      {
        label: "Emergente",
        data: [2800, 5000, 115, 115, 90000, 115, 115, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0],
        backgroundColor: "#63B3ED"
      },
      {
        label: "Alto",
        data: [0, 0, 0, 0, 0, 0, 0, 2800, 2800, 390, 390, 115, 115, 5000, 390, 115, 390, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: "#D63384"
      },
      {
        label: "Médio",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 115, 390, 390, 390, 0, 0, 0, 0, 0],
        backgroundColor: "#20C997"
      },
      {
        label: "Baixo",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 115, 2800, 365, 180, 0],
        backgroundColor: "#6F42C1"
      }
    ]
  };
  return (
    <div style={{ height: "600px" }}>
      <h3>Plano de Produção</h3>
      <HorizontalBar data={data} options={options} plugins={[shortenBarsPlugin]} />
    </div>
  );
};

export default ProductionPlanGraph;
