import React from 'react';
import { Table, Progress } from 'reactstrap';

function TabelaDadosV2({ dados }) {

  
  //const species = dados;
  const species = dados;
  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Espécie</th>
          <th scope="col">Nome Cientifico</th>
          <th scope="col">Carro chefe</th>
          <th scope="col">Ocupação</th>
          <th scope="col">Produtividade</th>
          <th scope="col">Renda</th>
          <th scope="col">Quantidade</th>
          <th scope="col">Tipo Canteiro</th>
          <th scope="col">Estágio sucessão</th>
          <th scope="col">Participação %</th>
        </tr>
      </thead>
      <tbody>
        {species.map((item) => (
          <tr key={item.id}>
            <th scope="row">{item.nome_comum}</th>
            <th>{item.nome_cientifico}</th>
            <th>{item.carrochefe ? "Sim" : "Não"}</th>
            <th>{item.ocupacao}</th>
            <td>{item.produtividade_total || "-"}</td>
            <td>{item.renda_total || "-"}</td>
            <td>{item.quantidade_de_individuos || "-"}</td>
            <th>{item.tipo_de_canteiro.join(' | ')}</th>
            <th>{item.estagio_sucessao.join(' | ')}</th>
            <td>
                <div className="d-flex align-items-center">
                    <span className="mr-2">{item.porcentagem.toFixed(2)}%</span>
                    <div>
                      <Progress
                        max="100"
                        value={item.porcentagem}
                        barClassName={
                          item.porcentagem < 5
                            ? "bg-danger" // Vermelho para abaixo de 30%
                            : item.porcentagem > 20
                            ? "bg-success" // Verde para acima de 80%
                            : item.porcentagem > 10
                            ? "bg-primary" // Azul para acima de 60%
                            : "bg-warning" // laranja para entre 30% e 60%
                        }
                      />
                    </div>
                </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default TabelaDadosV2;
